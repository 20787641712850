var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"main"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.savePopup)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('h1',{staticClass:"is-size-3"},[_c('b',[_vm._v("Assessment Setup")])])]),_c('div',{staticClass:"column bar"},[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('h3',{staticClass:"is-size-6"},[_vm._v("Assessment")])]),_c('li',[_c('router-link',{attrs:{"to":{ name: "assessment" }}},[_c('h3',{staticClass:"is-size-6"},[_vm._v("Assessment Setup")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: "showAssessment" }}},[_c('h3',{staticClass:"is-size-6"},[_vm._v("Assessment Detail")])])],1),_c('li',[_c('h3',{staticClass:"is-size-6 breadcrumb-item"},[(_vm.$route.name == 'createAssessmentSection')?[_vm._v(" Add Section ")]:(_vm.$route.name == 'editAssessmentSection')?[_vm._v(" Edit Section ")]:_vm._e()],2)])])])])]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('h1',{staticClass:"title"},[_vm._v("Section")])]),_c('div',{staticClass:"column is-6"},[_c('b-button',{attrs:{"type":"is-hcc"},on:{"click":function($event){_vm.isModalPreviewActive = true}}},[_vm._v(" Preview ")])],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"grouped":"","position":"is-right"}},[_c('b-button',{attrs:{"type":"is-hcc","native-type":"submit"}},[_vm._v("Save")])],1)],1)]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns is-multiline is-gapless"},[_c('div',{staticClass:"column is-3"},[_c('b',[_vm._v("Title")])]),_c('div',{staticClass:"column is-9"},[_c('ValidationProvider',{attrs:{"vid":"title","name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"text","placeholder":"Title"},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('b',[_vm._v("Category")])]),_c('div',{staticClass:"column is-9"},[_c('ValidationProvider',{attrs:{"vid":"category","name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-taginput',{attrs:{"data":_vm.categoryList,"open-on-focus":false,"loading":_vm.isFetchingCategory,"autocomplete":"","field":"name","maxtags":"1","placeholder":"Type to search category"},on:{"typing":_vm.getCategory},model:{value:(_vm.data.category),callback:function ($$v) {_vm.$set(_vm.data, "category", $$v)},expression:"data.category"}},[_c('template',{slot:"empty"},[_vm._v("There are no items")])],2)],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('br'),_c('br'),_c('div',{staticClass:"column is-3"},[_c('b',[_vm._v("Random Type")])]),_c('div',{staticClass:"column is-9"},[_c('b-checkbox',{attrs:{"type":"is-hcc"},model:{value:(_vm.data.isRandomTag),callback:function ($$v) {_vm.$set(_vm.data, "isRandomTag", $$v)},expression:"data.isRandomTag"}},[_vm._v(" Random Tag ")])],1),_c('br'),_c('br'),_c('div',{staticClass:"column is-3"},[_c('b',[_vm._v("Time")])]),_c('div',{staticClass:"column is-9"},[_c('b-checkbox',{attrs:{"type":"is-hcc"},model:{value:(_vm.data.isUseTime),callback:function ($$v) {_vm.$set(_vm.data, "isUseTime", $$v)},expression:"data.isUseTime"}},[_vm._v(" Use Time ")])],1),_c('br'),_c('br'),(_vm.data.isUseTime)?[_c('div',{staticClass:"column is-3"}),_c('div',{staticClass:"column is-9"},[_c('ValidationProvider',{attrs:{"vid":"time","name":"Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"number","placeholder":"Time"},model:{value:(_vm.data.time),callback:function ($$v) {_vm.$set(_vm.data, "time", $$v)},expression:"data.time"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]:_vm._e(),_c('div',{staticClass:"column is-3"},[_c('b',[_vm._v("Introduction")])]),_c('div',{staticClass:"column is-9"},[_c('b-checkbox',{attrs:{"type":"is-hcc"},model:{value:(_vm.data.isUseIntroduction),callback:function ($$v) {_vm.$set(_vm.data, "isUseIntroduction", $$v)},expression:"data.isUseIntroduction"}},[_vm._v(" Use Introduction ")])],1),_c('br'),_c('br'),(_vm.data.isUseIntroduction)?[_c('div',{staticClass:"column is-3"}),_c('div',{staticClass:"column is-9"},[_c('ValidationProvider',{attrs:{"vid":"isUseIntroduction","name":"Tag Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('ckeditor',{attrs:{"config":_vm.editorConfig},model:{value:(_vm.data.introduction),callback:function ($$v) {_vm.$set(_vm.data, "introduction", $$v)},expression:"data.introduction"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]:_vm._e(),_c('div',{staticClass:"column is-3"},[_c('b',[_vm._v("Example")])]),_c('div',{staticClass:"column is-9"},[_c('b-checkbox',{attrs:{"type":"is-hcc"},model:{value:(_vm.data.isUseExample),callback:function ($$v) {_vm.$set(_vm.data, "isUseExample", $$v)},expression:"data.isUseExample"}},[_vm._v(" Use Example ")])],1),_c('br'),_c('br'),(_vm.data.isUseExample)?[_c('div',{staticClass:"column is-3"}),_c('div',{staticClass:"column is-9"},[_c('ValidationProvider',{attrs:{"vid":"isUseExample","name":"Tag Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-taginput',{attrs:{"data":_vm.exampleQuestionList,"open-on-focus":false,"loading":_vm.isFetchingExampleQuestion,"autocomplete":"","field":"name","placeholder":"Type to search example question"},on:{"typing":_vm.getQuestionStorage},model:{value:(_vm.data.exampleQuestion),callback:function ($$v) {_vm.$set(_vm.data, "exampleQuestion", $$v)},expression:"data.exampleQuestion"}},[_c('template',{slot:"empty"},[_vm._v("There are no items")])],2)],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]:_vm._e(),_c('div',{staticClass:"column is-3"},[_c('b',[_vm._v("Question To Display")])]),_c('div',{staticClass:"column is-9"},[_c('ValidationProvider',{attrs:{"vid":"questionToDisplay","name":"Question To Display","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"number","placeholder":"Question To Display","min":"1"},model:{value:(_vm.data.questionToDisplay),callback:function ($$v) {_vm.$set(_vm.data, "questionToDisplay", $$v)},expression:"data.questionToDisplay"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],2),_vm._l((_vm.data.levels),function(level,index){return _c('div',{key:index,staticClass:"columns is-multiline is-gapless"},[_c('div',{staticClass:"column is-3"},[_c('b',[_vm._v("Level")])]),_c('div',{staticClass:"column is-9"},[_c('ValidationProvider',{attrs:{"vid":("level-" + index),"name":"Level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-select',{attrs:{"expanded":"","placeholder":"Select Level"},model:{value:(level.level),callback:function ($$v) {_vm.$set(level, "level", $$v)},expression:"level.level"}},_vm._l((_vm.levelList),function(dataLevel,indexLevel){return _c('option',{key:indexLevel,domProps:{"value":dataLevel.id}},[_vm._v(" "+_vm._s(dataLevel.name)+" ")])}),0)],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('b',[_vm._v("Limit Question")])]),_c('div',{staticClass:"column is-9"},[_c('ValidationProvider',{attrs:{"vid":("questionToShow-" + index),"name":"Limit Question","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"number","placeholder":"Limit Question","min":"1"},model:{value:(level.questionToShow),callback:function ($$v) {_vm.$set(level, "questionToShow", $$v)},expression:"level.questionToShow"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.data.isRandomTag)?[_c('div',{staticClass:"column is-3"},[_c('b',[_vm._v("Tag Type")])]),_c('div',{staticClass:"column is-9"},[_c('ValidationProvider',{attrs:{"vid":("tag-" + index),"name":"Tag Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-taginput',{attrs:{"data":_vm.tagList,"open-on-focus":false,"loading":_vm.isFetchingTag,"autocomplete":"","field":"name","placeholder":"Type to search tag"},on:{"typing":_vm.getTag},model:{value:(level.tag),callback:function ($$v) {_vm.$set(level, "tag", $$v)},expression:"level.tag"}},[_c('template',{slot:"empty"},[_vm._v("There are no items")])],2)],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]:_vm._e(),_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"grouped":"","position":"is-right"}},[_c('b-button',{attrs:{"icon-left":"trash"},on:{"click":function($event){return _vm.deleteLevelPopup(index)}}})],1)],1)],2)}),_c('b-field',[_c('b-button',{attrs:{"icon-left":"plus-circle","type":"is-hcc","title":"Add Level"},on:{"click":_vm.addLevels}},[_c('span',{staticClass:"pull-left"},[_vm._v("Add Level")])])],1)],2)])]}}])})],1),_c('b-modal',{attrs:{"active":_vm.isModalPreviewActive,"has-modal-card":"","full-screen":"","scroll":"keep"},on:{"update:active":function($event){_vm.isModalPreviewActive=$event}}},[_c('preview-section',{attrs:{"data":_vm.data,"type":'form'}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }