<template>
  <div>
    <section class="main">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(savePopup)">
          <!-- For breadcrumb -->
          <div class="columns is-multiline">
            <div class="column is-12">
              <h1 class="is-size-3"><b>Assessment Setup</b></h1>
            </div>
            <div class="column bar">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <h3 class="is-size-6">Assessment</h3>
                  </li>
                  <li>
                    <router-link :to="{ name: `assessment` }">
                      <h3 class="is-size-6">Assessment Setup</h3>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: `showAssessment` }">
                      <h3 class="is-size-6">Assessment Detail</h3>
                    </router-link>
                  </li>
                  <li>
                    <h3 class="is-size-6 breadcrumb-item">
                      <template
                        v-if="$route.name == 'createAssessmentSection'"
                      >
                        Add Section
                      </template>
                      <template
                        v-else-if="$route.name == 'editAssessmentSection'"
                      >
                        Edit Section
                      </template>
                    </h3>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div class="columns is-multiline">
            <!-- For title -->
            <div class="column is-12">
              <h1 class="title">Section</h1>
            </div>

            <!-- For button -->
            <div class="column is-6">
              <b-button type="is-hcc" @click="isModalPreviewActive = true">
                Preview
              </b-button>
            </div>
            <div class="column is-6">
              <b-field grouped position="is-right">
                <b-button type="is-hcc" native-type="submit">Save</b-button>
              </b-field>
            </div>
          </div>

          <div class="box">
            <div class="columns is-multiline is-gapless">
              <!-- For Title -->
              <div class="column is-3">
                <b>Title</b>
              </div>
              <div class="column is-9">
                <ValidationProvider
                  :vid="`title`"
                  name="Title"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field :type="errors.length > 0 ? 'is-danger' : null">
                    <b-input
                      type="text"
                      placeholder="Title"
                      v-model="data.title"
                    ></b-input>
                  </b-field>
                  <span class="required">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!-- For category -->
              <div class="column is-3">
                <b>Category</b>
              </div>
              <div class="column is-9">
                <ValidationProvider
                  :vid="`category`"
                  name="Category"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field :type="errors.length > 0 ? 'is-danger' : null">
                    <b-taginput
                      v-model="data.category"
                      :data="categoryList"
                      :open-on-focus="false"
                      :loading="isFetchingCategory"
                      autocomplete
                      field="name"
                      maxtags="1"
                      placeholder="Type to search category"
                      @typing="getCategory"
                    >
                      <template slot="empty">There are no items</template>
                    </b-taginput>
                  </b-field>
                  <span class="required">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <br />
              <br />

              <!-- For random type -->
              <div class="column is-3">
                <b>Random Type</b>
              </div>
              <div class="column is-9">
                <b-checkbox type="is-hcc" v-model="data.isRandomTag">
                  Random Tag
                </b-checkbox>
              </div>

              <br />
              <br />

              <!-- For time -->
              <div class="column is-3">
                <b>Time</b>
              </div>
              <div class="column is-9">
                <b-checkbox type="is-hcc" v-model="data.isUseTime">
                  Use Time
                </b-checkbox>
              </div>

              <br />
              <br />

              <!-- For time input -->
              <template v-if="data.isUseTime">
                <div class="column is-3"></div>
                <div class="column is-9">
                  <ValidationProvider
                    :vid="`time`"
                    name="Time"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-field :type="errors.length > 0 ? 'is-danger' : null">
                      <b-input
                        type="number"
                        placeholder="Time"
                        v-model="data.time"
                      ></b-input>
                    </b-field>
                    <span class="required">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </template>

              <!-- For introduction -->
              <div class="column is-3">
                <b>Introduction</b>
              </div>
              <div class="column is-9">
                <b-checkbox type="is-hcc" v-model="data.isUseIntroduction">
                  Use Introduction
                </b-checkbox>
              </div>

              <br />
              <br />

              <!-- For tag type -->
              <template v-if="data.isUseIntroduction">
                <div class="column is-3"></div>
                <div class="column is-9">
                  <ValidationProvider
                    :vid="`isUseIntroduction`"
                    name="Tag Type"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-field :type="errors.length > 0 ? 'is-danger' : null">
                      <ckeditor
                        v-model="data.introduction"
                        :config="editorConfig"
                      />
                    </b-field>
                    <span class="required">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </template>

              <!-- For example -->
              <div class="column is-3">
                <b>Example</b>
              </div>
              <div class="column is-9">
                <b-checkbox type="is-hcc" v-model="data.isUseExample">
                  Use Example
                </b-checkbox>
              </div>

              <br />
              <br />

              <!-- For tag type -->
              <template v-if="data.isUseExample">
                <div class="column is-3"></div>
                <div class="column is-9">
                  <ValidationProvider
                    :vid="`isUseExample`"
                    name="Tag Type"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-field :type="errors.length > 0 ? 'is-danger' : null">
                      <b-taginput
                        v-model="data.exampleQuestion"
                        :data="exampleQuestionList"
                        :open-on-focus="false"
                        :loading="isFetchingExampleQuestion"
                        autocomplete
                        field="name"
                        placeholder="Type to search example question"
                        @typing="getQuestionStorage"
                      >
                        <template slot="empty">There are no items</template>
                      </b-taginput>
                    </b-field>
                    <span class="required">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </template>

              <!-- For question to display -->
              <div class="column is-3">
                <b>Question To Display</b>
              </div>
              <div class="column is-9">
                <ValidationProvider
                  :vid="`questionToDisplay`"
                  name="Question To Display"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field :type="errors.length > 0 ? 'is-danger' : null">
                    <b-input
                      type="number"
                      placeholder="Question To Display"
                      v-model="data.questionToDisplay"
                      min="1"
                    ></b-input>
                  </b-field>
                  <span class="required">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div
              v-for="(level, index) in data.levels"
              :key="index"
              class="columns is-multiline is-gapless"
            >
              <!-- For level -->
              <div class="column is-3">
                <b>Level</b>
              </div>
              <div class="column is-9">
                <ValidationProvider
                  :vid="`level-${index}`"
                  name="Level"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field :type="errors.length > 0 ? 'is-danger' : null">
                    <b-select
                      v-model="level.level"
                      expanded
                      placeholder="Select Level"
                    >
                      <option
                        v-for="(dataLevel, indexLevel) in levelList"
                        :key="indexLevel"
                        :value="dataLevel.id"
                      >
                        {{ dataLevel.name }}
                      </option>
                    </b-select>
                  </b-field>
                  <span class="required">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!-- For limit question -->
              <div class="column is-3">
                <b>Limit Question</b>
              </div>
              <div class="column is-9">
                <ValidationProvider
                  :vid="`questionToShow-${index}`"
                  name="Limit Question"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field :type="errors.length > 0 ? 'is-danger' : null">
                    <b-input
                      type="number"
                      placeholder="Limit Question"
                      v-model="level.questionToShow"
                      min="1"
                    ></b-input>
                  </b-field>
                  <span class="required">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!-- For tag type -->
              <template v-if="!data.isRandomTag">
                <div class="column is-3">
                  <b>Tag Type</b>
                </div>
                <div class="column is-9">
                  <ValidationProvider
                    :vid="`tag-${index}`"
                    name="Tag Type"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-field :type="errors.length > 0 ? 'is-danger' : null">
                      <b-taginput
                        v-model="level.tag"
                        :data="tagList"
                        :open-on-focus="false"
                        :loading="isFetchingTag"
                        autocomplete
                        field="name"
                        placeholder="Type to search tag"
                        @typing="getTag"
                      >
                        <template slot="empty">There are no items</template>
                      </b-taginput>
                    </b-field>
                    <span class="required">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </template>

              <div class="column is-12">
                <b-field grouped position="is-right">
                  <b-button
                    icon-left="trash"
                    @click="deleteLevelPopup(index)"
                  ></b-button>
                </b-field>
              </div>
            </div>

            <b-field>
              <b-button
                icon-left="plus-circle"
                type="is-hcc"
                title="Add Level"
                @click="addLevels"
              >
                <span class="pull-left">Add Level</span>
              </b-button>
            </b-field>
          </div>
        </form>
      </ValidationObserver>
    </section>

    <!-- For Modal Assessment Preview -->
    <b-modal
      :active.sync="isModalPreviewActive"
      has-modal-card
      full-screen
      scroll="keep"
    >
      <preview-section :data="data" :type="'form'" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PreviewSection from '@/components/V2/Section/PreviewSection.vue'

export default {
  components: {
    PreviewSection,
  },
  name: 'form-section',
  data() {
    return {
      data: {
        title: null,
        category: [],
        isRandomTag: false,
        isUseTime: false,
        isUseIntroduction: false,
        introduction: null,
        isUseExample: false,
        exampleQuestion: [],
        time: null,
        questionToDisplay: 1,
        levels: [],
      },
      isFetchingExampleQuestion: false,
      exampleQuestionList: [],
      isFetchingCategory: false,
      categoryList: [],
      isFetchingTag: false,
      isModalPreviewActive: false,
      tagList: [],
      levelList: [],
      deletedLevels: [],

      editorConfig: {
        // The configuration of the editor.
        extraAllowedContent: 'div[class]',
        allowedContent: true,
        // filebrowserUploadMethod: "form",
        // filebrowserUploadUrl: process.env.VUE_APP_BACKEND_URL + "/api/ckeditor/upload-image",
        extraPlugins: 'justify,font',
      },
    }
  },
  created() {
    if (this.$route.name == 'editAssessmentSection') {
      this.fetchData()
    }

    this.getLevel()
  },
  methods: {
    ...mapActions({
      filterCategoryByName: 'newCategory/filterCategoryByName',
      getAllLevel: 'newLevel/getAllLevel',
      filterTagByName: 'newTag/filterTagByName',
      filterQuestionStorageByName:
        'questionStorage/filterQuestionStorageByName',
      fetchSection: 'newSection/fetchSection',
      createSection: 'newSection/createSection',
      updateSection: 'newSection/updateSection',
    }),

    async fetchData() {
      const response = await this.fetchSection(this.$route.params.newSectionId)

      response.data.category = [response.data.category]

      this.data = response.data
    },

    savePopup() {
      this.$buefy.dialog.confirm({
        title: 'Save Section',
        message: `Are you sure want to save this section?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, save it!',
        type: 'is-success',
        onConfirm: () => this.saveSection(),
      })
    },

    async saveSection() {
      let data = {
        assessmentId: this.$route.params.newAssessmentId,
        id: this.data.id,
        title: this.data.title,
        category: this.data.category,
        isRandomTag: this.data.isRandomTag,
        isUseTime: this.data.isUseTime,
        time: this.data.time,
        isUseIntroduction: this.data.isUseIntroduction,
        introduction: this.data.introduction,
        isUseExample: this.data.isUseExample,
        exampleQuestion: this.data.exampleQuestion,
        questionToDisplay: this.data.questionToDisplay,
        levels: this.data.levels,
        deletedLevels: this.deletedLevels,
      }

      try {
        if (this.$route.name == 'createAssessmentSection') {
          const response = await this.createSection(data)

          if (response.data.status == 500) {
            this.danger('Assessment already in use')
          } else {
            this.success('Create Section Success')
          }
        } else {
          const response = await this.updateSection(data)

          if (response.data.status == 500) {
            this.danger('Section already in use')
          } else {
            this.success('Update Section Success')
          }
        }

        this.$router.push({ name: 'showAssessment' })
      } catch (err) {
        this.danger(err)
      }
    },

    addLevels() {
      this.data.levels.push({
        id: null,
        level: null,
        questionToShow: 1,
        tag: [],
      })
    },

    deleteLevelPopup(index) {
      this.$buefy.dialog.confirm({
        title: 'Delete Level',
        message: `Are you sure want to delete this level?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-danger',
        onConfirm: () => this.deleteLevel(index),
      })
    },

    deleteLevel(index) {
      this.deletedLevels.push(this.data.levels[index])
      this.data.levels.splice(index, 1)
    },

    async getCategory(event) {
      let data = {
        search: event,
      }

      this.isFetchingCategory = true
      const response = await this.filterCategoryByName(data)

      this.categoryList = response.data
      this.isFetchingCategory = false
    },

    async getTag(event) {
      let data = {
        search: event,
      }

      this.isFetchingTag = true
      const response = await this.filterTagByName(data)

      this.tagList = response.data
      this.isFetchingTag = false
    },

    async getQuestionStorage(event) {
      let data = {
        search: event,
        category: this.data.category[0].id,
      }

      this.isFetchingQuestionStorage = true
      const response = await this.filterQuestionStorageByName(data)

      this.exampleQuestionList = response.data
      this.isFetchingQuestionStorage = false
    },

    async getLevel() {
      const response = await this.getAllLevel()

      this.levelList = response.data
    },

    // For succes alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
}
</script>
